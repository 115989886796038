<template>
    <div class="carousel__arrows" data-glide-el="controls">
        <div class="carousel__arrow carousel__arrow--left" data-glide-dir="<">
            <button class="no-icon"><component class="carousel__arrow-icon" :is="iconComponentLeft"></component></button>
        </div>
        <div class="carousel__arrow carousel__arrow--right" data-glide-dir=">">
            <button class="no-icon"><component class="carousel__arrow-icon" :is="iconComponentRight"></component></button>
        </div>
    </div>
</template>

<script>
import {ref} from 'vue';
import useSvgSprite from "../../../composables/use-svg-sprite";

export default {
    props: {
        iconLeft: {
            type: String,
            default: 'chevron-left'
        },
        iconRight: {
            type: String,
            default: 'chevron-right'
        }
    },

    setup(props) {
        // pull in the svg-sprite composable
        const {getSvg} = useSvgSprite(props);

        const iconComponentLeft = ref(null);
        const iconComponentRight = ref(null);

        // get the left & right SVGs
        iconComponentLeft.value = getSvg(props.iconLeft);
        iconComponentRight.value = getSvg(props.iconRight);

        return {
            iconComponentLeft,
            iconComponentRight,
        };
    }
}
</script>
