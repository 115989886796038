(function () {
    let embeddedVideo = document.querySelectorAll('iframe[src^="https://www.youtube"]');

    if (embeddedVideo && embeddedVideo.length) {

        function setEmbeddedVideoHeight() {
            for (let i = 0; i < embeddedVideo.length; i++) {
                embeddedVideo[i].style.height = embeddedVideo[i].offsetWidth * .5625 + 'px';
            }
        }

        window.addEventListener("resize", setEmbeddedVideoHeight);
        setEmbeddedVideoHeight();
    }
})();

