<template>
    <div class="lightbox">
        <slot name="items" v-bind="{ items }">
            <div class="items" v-if="!isCarousel">
                <div v-for="(item, index) in items" class="item" :key="index">
                    <slot name="item" v-bind="{ item, itemIndex: index}">
                        <img :src="item['url']" :alt="item['text']" @click="openLightboxOnSlide(index + 1)">
                        <p class="caption">{{ item['text']}}</p>
                    </slot>
                </div>
            </div>
            <carousel v-bind="carouselProps" v-else>
                <template v-for="(item, index) in items" class="item" :key="index">
                    <slot name="item" v-bind="{ item, itemIndex: index}">
                        <img :src="item['url']" :alt="item['text']" @click="openLightboxOnSlide(index + 1)" :key="index">
                    </slot>
                </template>
            </carousel>
        </slot>
        <Teleport :to="teleportTo">
            <FsLightbox
                :toggler=toggler
                :slide=slide
                :sources=sources
            />
        </Teleport>
    </div>
</template>

<script>
import FsLightbox from "fslightbox-vue/v3";
import { Teleport } from "vue";
import Carousel from "../carousel/Carousel";

export default {
    components: {
        FsLightbox,
        Teleport,
        Carousel,
    },

    props: {
        items: {
            type: Array,
            default: []
        },
        name: {
            type: String,
        },
        teleportTo: {
            type: String,
            default: 'body'
        },
        displayType: {
            type: String,
            default: 'grid'
        },
        assetType: {
            type: String,
            default: 'image'
        },
        start: {
            type: Number,
            default: 1
        },
        carouselProps: {
            type: Object,
            default: () => {
                return {
                    'per-view': 3,
                    'gap': 20
                };
            }
        }
    },

    data() {
        return {
            toggler: false,
            slide: this.start
        }
    },

    mounted() {
        let togglesEls = document.querySelectorAll('.lightbox-trigger[data-lightbox-name="' + this.name + '"]');
        for(let togglesEl of togglesEls) {
            togglesEl.addEventListener('click', (e)=>{
                this.toggler = !this.toggler;
            });
        }
    },
    computed: {
        sources() {
            return this.items.map(item => item['url']);
        },
        isCarousel() {
            return this.displayType === 'carousel';
        }
    },
    methods: {
        openLightboxOnSlide(number) {
            this.slide = number;
            this.toggler = !this.toggler;
        }
    }
};
</script>
