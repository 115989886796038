import {h, onMounted} from 'vue';

const useSvgSprite = (props) => {
    const propsData = {
        spritesElementId: 'svg-sprites',
        ...props,
    };

    const sprites = document.getElementById(propsData.spritesElementId);

    const getSvg = name => {

        const svgElement = document.getElementById(name);

        let attributes = {};
        for (let i = 0; i < svgElement.attributes.length; i++) {
            let elementAttribute = svgElement.attributes[i];
            if (elementAttribute.specified) {
                attributes[elementAttribute.name] = elementAttribute.value;
            }
        }

        return h(
            'svg',
            {
                ...attributes,
                innerHTML: svgElement.innerHTML
            },
        );
    };

    return {
        getSvg
    };
};

export default useSvgSprite;
